import copy from "copy-to-clipboard";
import Image from "next/image";
import { ArrowLeftCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";
import { Collection } from "database";
import { getCsrfToken, signIn, signOut, useSession } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
import { SiweMessage } from "siwe";
import {
  useAccount,
  useBalance,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from "wagmi";
import { Dialog } from "@headlessui/react";
import { constants } from "ethers";
import { toast } from "react-toastify";

import { Tooltip } from "@components/Tooltip";
import { MarketplaceLink } from "@components/MarketplaceLink";
import { LoadingModalHandle } from "@components/LoadingModal";
import { isWeb3AuthConnector } from "./Web3AuthConnector";

import { useDeploys } from "@hooks/signer";
import { useFirstTimeGift } from "@hooks/firstTime";
import { parseAddress, simplifyAddress } from "@utils/index";
import { AsyncLoad, Fail, SignatureRequired } from "@utils/loadingStates";

import DarkSpaceshipIcon from "@public/img/spaceship_icon.png";
import LightSpaceshipIcon from "@public/img/spaceship_icon_white.png";

// HOC to have components only be rendered after mount to avoid hydration errors
// All web3 components will need to be wrapped in this since they access
export const onlyMounted = <T extends Record<string, unknown>>(
  Component: React.FC<T>,
  Fallback?: React.FC<T>
) => {
  const Wrapped: React.FC<T> = (props) => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
      setMounted(true);
    }, []);
    if (!mounted) {
      if (Fallback) return <Fallback {...props} />;
      return null;
    }
    return <Component {...props} />;
  };
  return Wrapped;
};

type WalletIconProps = {
  onClick: () => void;
  loader: React.MutableRefObject<LoadingModalHandle | null>;
};

export const WalletIcon = onlyMounted<WalletIconProps>(
  ({ onClick, loader }) => {
    const [copyToClipboardText, setCopyToClipboardText] = useState(
      "Copy address to clipboard"
    );
    useFirstTimeGift();
    const { SpaceChips } = useDeploys();
    const [timesTriedToSignIn, setTimesTriedToSignIn] = useState(0);
    const { data: session, status } = useSession();
    const { isConnected, address, connector } = useAccount();
    const { disconnect } = useDisconnect();
    const { chain } = useNetwork();
    const { signMessageAsync } = useSignMessage();

    const handleDisconnect = useCallback(async () => {
      await signOut({
        redirect: false,
      });
      disconnect();
      setTimesTriedToSignIn(0);
    }, [disconnect]);

    const handleLogin = useCallback(async () => {
      try {
        const chainId = chain?.id;
        if (!address || !chainId) {
          return;
        }
        let userInfo = {};
        loader.current?.push(
          AsyncLoad({
            message: "Authenticating Wallet",
          })
        );
        if (connector && isWeb3AuthConnector(connector)) {
          userInfo = await connector.web3AuthInstance.getUserInfo();
        } else {
          loader.current?.push(SignatureRequired());
        }
        const message = new SiweMessage({
          domain: window.location.host,
          address,
          statement: "Sign in with Ethereum to the app.",
          uri: window.location.origin,
          version: "1",
          chainId,
          nonce: await getCsrfToken(),
        });

        const signature = await signMessageAsync({
          message: message.prepareMessage(),
        });

        const response = await signIn("credentials", {
          message: JSON.stringify(message),
          redirect: false,
          signature,
          userInfo: JSON.stringify(userInfo),
        });
        if (response && (response.error || !response.ok)) {
          console.log("Error signing in");
          loader.current?.push(
            Fail({
              message: "Failed to authenticate",
            })
          );
          handleDisconnect();
          return;
        }
        loader.current?.reset();
        toast.success("Wallet authenticated");
      } catch (error) {
        console.log("Error signing in");
        handleDisconnect();
      } finally {
        setTimesTriedToSignIn((state) => state + 1);
      }
    }, [address, chain?.id, signMessageAsync, connector]);

    useEffect(() => {
      const isTheFirstTime = timesTriedToSignIn === 0;
      const isUnauthenticated = status === "unauthenticated";

      if (isConnected && isUnauthenticated && isTheFirstTime) {
        handleLogin();
      }
    }, [isConnected, timesTriedToSignIn, status, handleLogin]);

    useEffect(() => {
      const revalidateSignIn = async () => {
        setTimesTriedToSignIn(0);
        await signOut({
          redirect: false,
        });
      };

      const currentAddressDifferFromSessionAddress =
        session?.address && address !== session.address;

      if (currentAddressDifferFromSessionAddress) {
        revalidateSignIn();
      }
    }, [address, handleLogin, session?.address]);

    const handleCopyToClipboard = (text: string) => {
      setCopyToClipboardText("Address copied!");
      copy(text);
      setTimeout(() => {
        setCopyToClipboardText("Copy address to clipboard");
      }, 3000);
    };

    if (!isConnected || !address)
      return (
        <div
          onClick={onClick}
          className="js-wallet group flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
          </svg>
        </div>
      );
    return (
      <div className="js-nav-dropdown group-dropdown relative">
        <button
          className="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
          id="profileDropdown"
          aria-expanded="false"
          aria-label="profile"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
          </svg>
        </button>
        <div
          className="dropdown-menu !-right-4 !left-auto !top-[85%] z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:visible group-dropdown-hover:opacity-100 dark:bg-jacarta-800 md:invisible md:absolute md:grid md:!translate-y-4 md:py-4 md:px-2 md:opacity-0 md:shadow-2xl"
          aria-labelledby="profileDropdown"
        >
          <button
            id="address-button"
            className="js-copy-clipboard d-flex my-4 flex select-none items-center justify-center whitespace-nowrap rounded-xl py-2 px-5 font-display leading-none text-jacarta-700 transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600"
            onClick={() => handleCopyToClipboard(address)}
          >
            <span className="">{simplifyAddress(address)}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="ml-1 mb-px h-4 w-4 fill-jacarta-700 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zM4.003 9L4 20h11V9H4.003z" />
            </svg>
          </button>

          <Tooltip
            data-tooltip-id="address-button"
            content={copyToClipboardText}
            place="bottom"
          />

          <div className="mx-5 mb-6 rounded-lg border border-jacarta-100 p-4 dark:border-jacarta-600">
            <span className="text-sm font-medium tracking-tight dark:text-jacarta-200">
              Balance
            </span>
            <div className="flex items-center">
              <div className="mr-2 mt-1 inline">
                <Image
                  width={18}
                  height={18}
                  src={DarkSpaceshipIcon}
                  alt="CHIP logo"
                  className="dark:hidden"
                />

                <Image
                  width={18}
                  height={18}
                  src={LightSpaceshipIcon}
                  alt="CHIP logo"
                  className="hidden dark:block"
                />
              </div>
              <Balance token={parseAddress(SpaceChips)} />
              <span className="w-[15ch] overflow-hidden text-ellipsis text-lg font-bold text-green-500"></span>
            </div>
          </div>
          {session && (
            <>
              <MarketplaceLink
                href="/user/me"
                className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-5 w-5 fill-jacarta-700 transition-colors dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                </svg>
                <span className=" font-display text-sm text-jacarta-700 dark:text-white">
                  My Profile
                </span>
              </MarketplaceLink>
              <MarketplaceLink
                href={`/user/${parseAddress(address)}/edit`}
                className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
              >
                <Cog6ToothIcon className="h-5 w-5 fill-jacarta-700 transition-colors dark:fill-white" />
                <span className=" font-display text-sm text-jacarta-700 dark:text-white">
                  Edit Profile
                </span>
              </MarketplaceLink>
            </>
          )}

          <div
            role={"button"}
            onClick={handleDisconnect}
            className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
          >
            <ArrowLeftCircleIcon className="h-5 w-5 fill-jacarta-700 transition-colors dark:fill-white" />
            <span className=" font-display text-sm text-jacarta-700 dark:text-white">
              {session ? "Sign Out" : "Disconnect wallet"}
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export const Balance = onlyMounted<{ token?: AddressLike; symbol?: string }>(
  ({ token, symbol = "CHIP" }) => {
    const { address, isConnected } = useAccount();
    const {
      data: balanceData,
      isLoading: balanceLoading,
      error: balanceError,
    } = useBalance({
      address,
      watch: true,
      token,
      enabled: !!address && parseAddress(token) !== constants.AddressZero,
    });
    if (!isConnected) return null;
    if (balanceError)
      return (
        <div className="text-sm font-light text-red-500">
          There was an error fetching your balance
        </div>
      );

    if (balanceLoading)
      return <p className="text-jacarta-700 dark:text-white">Loading...</p>;
    const truncated = balanceData?.formatted
      ? balanceData.formatted.slice(0, balanceData.formatted.indexOf(".") + 3)
      : "0";
    return (
      <p className="text-jacarta-700 dark:text-white">
        {truncated} {balanceData?.symbol ?? symbol}
      </p>
    );
  }
);

export const VerifiedEditUser = onlyMounted(() => {
  const { isConnected, address } = useAccount();
  if (isConnected)
    return (
      <div>
        <MarketplaceLink href={`/user/${address}/edit`}>
          <button className="text-jacarta-700 dark:text-white">
            Edit Profile
          </button>
        </MarketplaceLink>
      </div>
    );
  return <></>;
});

export const VerifiedEditCollection = onlyMounted<{ collection: Collection }>(
  (props) => {
    const { isConnected, address } = useAccount();

    if (isConnected && address === parseAddress(props.collection.minterAddress))
      return (
        <div>
          <MarketplaceLink href={`/${props.collection.address}/edit`}>
            <button className="text-jacarta-700 dark:text-white">
              Edit Collection details
            </button>
          </MarketplaceLink>
        </div>
      );
    return <></>;
  }
);

export const ProfileModal = onlyMounted<{
  isOpen: boolean;
  onClose: () => void;
}>(({ isOpen, onClose }) => {
  const { data: session } = useSession();
  const { address } = useAccount();
  const { SpaceChips } = useDeploys();
  const { disconnect } = useDisconnect();

  const handleCopyToClipboard = (text: string) => {
    copy(text);
  };

  const handleDisconnect = useCallback(async () => {
    await signOut({
      redirect: false,
    });
    disconnect();
  }, [disconnect]);

  if (!address) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xs rounded-xl bg-white dark:bg-jacarta-800">
          <div className="z-10 min-w-[14rem] overflow-hidden whitespace-nowrap rounded-xl bg-white p-4 transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:visible group-dropdown-hover:opacity-100 dark:bg-jacarta-800 md:invisible md:absolute md:grid md:!translate-y-4 md:py-4 md:px-2 md:opacity-0 md:shadow-2xl">
            <div className="flex justify-end">
              <button
                className="js-mobile-close group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                aria-label="close mobile menu"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            <button
              className="d-flex my-4 flex w-full select-none items-center justify-center whitespace-nowrap rounded-xl py-2 px-5 font-display leading-none text-jacarta-700 transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600"
              onClick={() => handleCopyToClipboard(address)}
            >
              <span className="">{simplifyAddress(address)}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="ml-1 mb-px h-4 w-4 fill-jacarta-700 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zM4.003 9L4 20h11V9H4.003z" />
              </svg>
            </button>

            <div className="mx-5 mb-6 rounded-lg border border-jacarta-100 p-4 dark:border-jacarta-600">
              <span className="text-sm font-medium tracking-tight dark:text-jacarta-200">
                Balance
              </span>
              <div className="flex items-center">
                <div className="mr-2 mt-1 inline">
                  <Image
                    width={18}
                    height={18}
                    src={DarkSpaceshipIcon}
                    alt="CHIP logo"
                    className="dark:hidden"
                  />

                  <Image
                    width={18}
                    height={18}
                    src={LightSpaceshipIcon}
                    alt="CHIP logo"
                    className="hidden dark:block"
                  />
                </div>
                <Balance token={parseAddress(SpaceChips)} />
                <span className="w-[15ch] overflow-hidden text-ellipsis text-lg font-bold text-green-500"></span>
              </div>
            </div>

            {session && (
              <>
                <MarketplaceLink
                  href={`/user/${parseAddress(address)}`}
                  className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-5 w-5 fill-jacarta-700 transition-colors dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                  </svg>
                  <span className=" font-display text-sm text-jacarta-700 dark:text-white">
                    My Profile
                  </span>
                </MarketplaceLink>
                <MarketplaceLink
                  href={`/user/${parseAddress(address)}/edit`}
                  className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                >
                  <Cog6ToothIcon className="h-5 w-5 fill-jacarta-700 transition-colors dark:fill-white" />
                  <span className=" font-display text-sm text-jacarta-700 dark:text-white">
                    Edit Profile
                  </span>
                </MarketplaceLink>
              </>
            )}

            <div
              role={"button"}
              onClick={handleDisconnect}
              className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
            >
              <ArrowLeftCircleIcon className="h-5 w-5 fill-jacarta-700 transition-colors dark:fill-white" />
              <span className=" font-display text-sm text-jacarta-700 dark:text-white">
                {session ? "Sign Out" : "Disconnect wallet"}
              </span>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
});
