import Link from "next/link";
import type { ComponentProps } from "react";

type MarketplaceLinkProps = ComponentProps<typeof Link>;

export const MarketplaceLink = ({
  children,
  ...rest
}: MarketplaceLinkProps) => {
  return (
    <Link {...rest} href={`/marketplace${rest.href}`}>
      {children}
    </Link>
  );
};
