import { Tooltip as ReactTooltip } from "react-tooltip";
import type { ITooltip } from "react-tooltip";

export const Tooltip = (props: ITooltip) => {
  return (
    <ReactTooltip
      className="bg-jacarta-800 font-bold text-white dark:bg-light-base dark:text-jacarta-700"
      {...props}
    />
  );
};
