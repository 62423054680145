export const LinkToTransaction: React.FC<{
  hash: string;
  message?: string;
  wrapper?: boolean;
}> = ({ hash, message = "Check Transaction", wrapper }) => {
  if (!wrapper) {
    return (
      <a
        href={`https://mumbai.polygonscan.com/tx/${hash}`}
        target="__blank"
        className="text-sm underline"
      >
        {message}
      </a>
    );
  }
  return (
    <div>
      <a
        href={`https://mumbai.polygonscan.com/tx/${hash}`}
        target="__blank"
        className="text-sm underline"
      >
        {message}
      </a>
    </div>
  );
};
