import { LinkToTransaction } from "@components/toast";
import { trpc } from "@utils/trpc";
import { useSession } from "next-auth/react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useWaitTransaction } from "./signer";

export const useFirstTimeGift = () => {
  const { data } = useSession();
  const { mutateAsync: certifyGift, data: giftData } =
    trpc.web3.certifyFirstTimeGift.useMutation();
  const wait = useWaitTransaction();
  return useQuery({
    queryKey: ["firstTime", data],
    queryFn: async () => {
      if (!data) return;
      const { success, tx } = await certifyGift();
      if (success && tx) {
        toast.promise(wait(tx.hash), {
          success: {
            render: ({ data: receipt }) => {
              if (!receipt) throw new Error("Succeeded with no receipt");
              return (
                <div>
                  {"Here's"} 100 free CHIP tokens
                  <p>
                    <small>Go buy some art!</small>
                  </p>
                  <LinkToTransaction hash={receipt.transactionHash} />
                </div>
              );
            },
            autoClose: false,
          },
          error: {
            render: () => {
              return <div>Something went wrong</div>;
            },
          },
          pending: {
            render: () => {
              return (
                <div>
                  Welcome to Spaceship Marketplace
                  <p>
                    <small>{"We're"} preparing a nice gift!</small>
                  </p>
                </div>
              );
            },
          },
        });
      }
    },
    enabled: data !== null && !!data?.user?.name && !giftData?.success,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
